@tailwind base;
@tailwind components;
@tailwind utilities;

html{scroll-behavior:smooth}

.triangle-wrap {
  width: 50px;
  @apply absolute left-0 top-0 bottom-0 flex;
}

footer {
  @apply hidden;
}

.image-grid {
  @apply lg:max-w-[700px]
}

.test-clip.clip-1,
.test-clip.clip-2,
.test-clip.clip-3,
.test-clip.clip-4
 {
  height: 50vh;
  max-width: 400px;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  background: black;
  @apply relative bg-black min-h-[150px] md:min-h-[300px] lg:min-h-[250px] h-44 lg:h-[50vh] ml-[-33%] lg:ml-0 w-[163%] md:w-[32vw] lg:w-[25vw];
}

.test-clip img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 18px);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.test-clip img {
  position: absolute;
  top: 7px;
  right: 7px;
  width: calc(100% - 14px);
  height: calc(100% - 12px);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.triangle {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 100vh 50px 0 0;
  border-color: black transparent transparent transparent;
  transform: rotate(0deg);
}

body {
  @apply font-body text-sm md:text-base dark:text-gray-300 antialiased m-0 p-0 bg-black;
}


.button {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0% 30%);
  @apply relative text-center font-bold uppercase tracking-wide transition duration-300 text-base md:text-xl leading-none md:leading-none px-4 md:px-8 pt-5 pb-4 md:py-6 md:pb-5 focus:outline-none;
}

.button-primary {
  @apply bg-casio-red hover:bg-opacity-75 text-white;
}

.button-secondary {
  @apply bg-white hover:bg-opacity-75 text-black;
}

.quizoo-header {
  @apply bg-transparent fixed transition duration-500;
}

.choices li {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0% 30%);
  @apply  text-xs md:text-base lg:text-sm xl:text-base duration-300 p-3 md:p-4 xl:p-6 bg-casio-dark-grey relative cursor-pointer;
}

.choices li .description {
  @apply opacity-80 transition duration-100;
}
.choices li strong {
  @apply transition duration-100;
}

.choices li.selected .description {
  @apply opacity-100;
}

.choices li:hover .description {
  @apply opacity-100;
}

.choices strong {
  @apply font-bold text-xl leading-tight md:text-3xl lg:text-2xl xl:text-3xl md:leading-tight pr-12 uppercase block mb-1 md:mb-2;
}

.choices li:hover {
  @apply bg-white bg-opacity-25;
}

.choices li.selected {
  @apply bg-white text-black bg-opacity-100;
}

.choices li svg {
  @apply opacity-30 w-auto h-2 md:h-3 transition duration-100;
}

.choices li.selected svg {
  @apply opacity-100;
}

.choices .tick {
  @apply bg-white transition duration-300 bg-opacity-10 w-6 h-6 md:w-8 md:h-8 flex items-center justify-center rounded-full absolute top-2 right-2 md:top-3.5 md:right-3.5 xl:top-5 xl:right-5;
}

.choices li.selected .tick {
  @apply bg-casio-red;
}

/*
.choices li::after {
  @apply absolute right-0 bottom-0 pointer-events-none;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 20px 20px;
  border-color: transparent transparent #000 transparent;
}
*/
.watch-card, .watch-card span {
 @apply transform duration-300 will-change-transform;
 clip-path: polygon(0 0, 100% 0, 100% calc(100% - 28px), calc(100% - 28px) 100%, 0 100%, 0% 30%);
}
.watch-card img {
  @apply transform duration-300 will-change-transform ;
}

.watch-card:hover, .watch-card:hover span {
  @apply border-black;
}
.watch-card:hover img {
  @apply scale-105
}

.fade-out-animation {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  background-color: black;
  opacity: 0;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}



/* Add this class to your existing CSS */
.fade-animation {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.select-option-message {
  color: red;
  font-size: 14px;
  margin-top: 8px;
  display: block;
}

.fade-animation.show {
  opacity: 1;
  visibility: visible;
}


div.fade-in-backup {
  animation-name: fade;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes fade {
  0%   {opacity: 0;}
  100%  {opacity: 1;}
}

div.fade-in-slower {
  animation-name: fade2;
  animation-duration: 2s;
  opacity: 0;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes fade2 {
  0%   {opacity: 0;}
  100%  {opacity: 1;}
}

div.swipe-up {
  animation-name: example2;
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  margin-top: 0;
  background-color: black;
  max-height: 50vh;
  @apply absolute left-0 right-0 bottom-0 top-0 z-50; 
}

@keyframes example2 {
  0%   {margin-top: 0;}
  100%  {margin-top: -50vh;}
}

div.swipe-down {
  animation-name: example;
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  margin-top: 0;
  background-color: black;
  max-height: 50vh;
  @apply absolute left-0 right-0 bottom-0 top-0 z-50; 
}

@keyframes example {
  0%   {margin-top: 0;}
  100%  {margin-top: 50vh;}
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('../public/fonts/TradeGothicLTStd-BdCn20.woff2') format('woff2'),
      url('../public/fonts/TradeGothicLTStd-BdCn20.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('../public/fonts/TradeGothicLTStd.woff2') format('woff2'),
      url('../public/fonts/TradeGothicLTStd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Celluloid';
  src: url('../public/fonts/CelluloidJNL.woff2') format('woff2'),
      url('../public/fonts/CelluloidJNL.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



.fade-me {
  @apply opacity-100 transition duration-1000 will-change-transform bottom-0 left-0 right-0 top-0;
}

.fade-me.fade-in-backup {
  @apply opacity-100 top-3/4;
}

.fade-me2 {
  @apply opacity-0  will-change-transform ;
  animation-name: fade2;
  animation-duration: 1s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.fade-me2.fade-in-backup {
  @apply opacity-100;
}
